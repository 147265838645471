/* ListingsCards Component Styles */

/* ListingsCards Section */
.listings-cards-section {
  background-color: rgba(30, 31, 46, 0.8);
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.05);
  margin-top: 2rem;
}

.listings-cards-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  gap: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  padding-bottom: 1rem;
}

.listings-cards-title {
  font-size: 1.5rem;
  color: #f0f0f0;
  display: flex;
  align-items: baseline;
  position: relative;
}

.listings-cards-title::after {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  width: 50%;
  height: 2px;
  background: linear-gradient(45deg, rgba(253, 164, 175, 0.7), rgba(253, 186, 116, 0.7));
  border-radius: 2px;
}

.listings-cards-count {
  font-size: 1rem;
  color: rgba(253, 164, 175, 0.9);
  font-weight: normal;
  margin-left: 0.75rem;
}

.listings-cards-filters {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
  align-items: center;
}

.filter-select {
  background-color: rgba(43, 44, 61, 0.8);
  border: 1px solid rgba(253, 164, 175, 0.2);
  border-radius: 0.5rem;
  color: #f0f0f0;
  padding: 0.5rem 0.75rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter-select:hover, .filter-select:focus {
  background-color: rgba(59, 60, 77, 0.8);
  border-color: rgba(253, 164, 175, 0.4);
  outline: none;
}

.filter-select option {
  background-color: #1e1f2e;
  color: #f0f0f0;
}

/* Search input styling */
.search-container {
  position: relative;
  flex: 1;
  max-width: 300px;
  margin-right: 0.5rem;
}

.search-input {
  width: 100%;
  background-color: rgba(43, 44, 61, 0.8);
  border: 1px solid rgba(253, 164, 175, 0.2);
  border-radius: 0.5rem;
  color: #f0f0f0;
  padding: 0.5rem 0.75rem;
  font-size: 0.9rem;
  transition: all 0.2s ease;
}

.search-input:focus {
  outline: none;
  border-color: rgba(253, 164, 175, 0.4);
  background-color: rgba(59, 60, 77, 0.8);
  box-shadow: 0 0 0 2px rgba(253, 164, 175, 0.1);
}

.search-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/* View toggle button */
.view-toggle-btn {
  background-color: rgba(43, 44, 61, 0.8);
  border: 1px solid rgba(253, 164, 175, 0.2);
  border-radius: 0.5rem;
  color: #f0f0f0;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.view-toggle-btn:hover {
  background-color: rgba(59, 60, 77, 0.8);
  border-color: rgba(253, 164, 175, 0.4);
  transform: translateY(-2px);
}

/* Grid view */
.listings-cards-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

/* List view */
.listings-cards-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

/* Listing Card - Base Styles */
.listing-card {
  background-color: rgba(36, 39, 58, 0.7);
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.05);
  cursor: pointer;
}

.listing-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  border-color: rgba(253, 164, 175, 0.3);
}

/* Grid Mode */
.listing-card-grid {
  flex-direction: column;
}

/* List Mode */
.listing-card-list {
  flex-direction: row;
  height: auto;
  min-height: 160px;
}

.listing-card-list .listing-image {
  width: 200px;
  height: auto;
  min-height: 160px;
  flex-shrink: 0;
}

.listing-card-list .listing-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.listing-card-list .listing-description {
  -webkit-line-clamp: 2;
}

.listing-card-list .listing-header {
  flex-wrap: wrap;
}

/* Compact Mode */
.listing-card-compact {
  flex-direction: column;
  max-width: 220px;
}

.listing-card-compact .listing-image {
  height: 140px;
}

.listing-card-compact .listing-content {
  padding: 0.75rem;
}

.listing-card-compact .listing-title {
  font-size: 1rem;
}

.listing-card-compact .listing-description {
  -webkit-line-clamp: 2;
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
}

.listing-card-compact .listing-meta {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.listing-card-compact .listing-footer {
  padding-top: 0.5rem;
}

.listing-card-compact .listing-view-btn {
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
}

/* Chat Mode */
.listing-card-chat {
  flex-direction: row;
  max-width: 100%;
  height: auto;
  min-height: 100px;
  margin-bottom: 0.5rem;
}

.listing-card-chat .listing-image {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
}

.listing-card-chat .listing-content {
  padding: 0.75rem;
  flex: 1;
}

.listing-card-chat .listing-title {
  font-size: 1rem;
  margin-bottom: 0.25rem;
}

.listing-card-chat .listing-type {
  font-size: 0.7rem;
  padding: 0.15rem 0.35rem;
}

.listing-card-chat .listing-description {
  -webkit-line-clamp: 2;
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
}

.listing-card-chat .listing-meta {
  font-size: 0.8rem;
  margin-bottom: 0.25rem;
}

.listing-card-chat .listing-footer {
  padding-top: 0.5rem;
}

.listing-card-chat .listing-view-btn {
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
}

/* Listing Image */
.listing-image {
  height: 180px;
  overflow: hidden;
  position: relative;
}

.listing-image::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background: linear-gradient(to top, rgba(36, 39, 58, 0.7), transparent);
  pointer-events: none;
}

.listing-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.listing-card:hover .listing-image img {
  transform: scale(1.05);
}

.listing-image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, rgba(43, 44, 61, 0.8) 0%, rgba(25, 26, 41, 0.8) 100%);
  font-size: 2.5rem;
  color: rgba(253, 164, 175, 0.3);
}

/* Listing Content */
.listing-content {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.listing-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.75rem;
}

.listing-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #f0f0f0;
  margin-right: 0.5rem;
}

.listing-type {
  font-size: 0.75rem;
  background: linear-gradient(45deg, rgba(253, 164, 175, 0.8), rgba(253, 186, 116, 0.8));
  color: #1a1b26;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  white-space: nowrap;
}

.listing-meta {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.75rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}

.listing-meta span {
  display: flex;
  align-items: center;
  gap: 0.35rem;
}

.listing-description {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 1rem;
  font-size: 0.95rem;
  line-height: 1.5;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  flex: 1;
}

.listing-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 0.75rem;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}

.listing-date {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.6);
}

.listing-view-btn {
  background: linear-gradient(45deg, rgba(253, 164, 175, 0.8), rgba(253, 186, 116, 0.8));
  background-size: 200% 200%;
  animation: gradient-shift 5s ease infinite;
  border: none;
  border-radius: 0.5rem;
  color: #1a1b26;
  padding: 0.4rem 0.75rem;
  font-size: 0.85rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.listing-view-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(253, 164, 175, 0.3);
  opacity: 0.9;
}

/* Pagination */
.pagination-container {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}

.pagination-button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(43, 44, 61, 0.8);
  color: #f0f0f0;
  border: 1px solid rgba(253, 164, 175, 0.2);
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 600;
}

.pagination-button:hover {
  background-color: rgba(59, 60, 77, 0.8);
  border-color: rgba(253, 164, 175, 0.4);
  transform: translateY(-2px);
}

.pagination-button.active {
  background: linear-gradient(45deg, rgba(253, 164, 175, 0.8), rgba(253, 186, 116, 0.8));
  background-size: 200% 200%;
  animation: gradient-shift 5s ease infinite;
  color: #1a1b26;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border: none;
}

/* Empty State */
.listings-cards-empty {
  padding: 3rem 1rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}

.listings-cards-empty h3 {
  font-size: 1.5rem;
  color: #f0f0f0;
  margin-bottom: 1rem;
}

.listings-cards-empty p {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 1.5rem;
}

.add-listing-btn {
  background: linear-gradient(45deg, rgba(253, 164, 175, 0.8), rgba(253, 186, 116, 0.8));
  background-size: 200% 200%;
  animation: gradient-shift 5s ease infinite;
  border: none;
  border-radius: 0.5rem;
  color: #1a1b26;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
}

.add-listing-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(253, 164, 175, 0.3);
  opacity: 0.9;
}

/* Loading State */
.listings-cards-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 3rem 0;
}

.loading-spinner {
  border: 3px solid rgba(253, 164, 175, 0.2);
  border-top: 3px solid rgba(253, 164, 175, 0.8);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes gradient-shift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Error State */
.listings-cards-error {
  padding: 2rem;
  text-align: center;
  color: #ff6b6b;
  background-color: rgba(255, 107, 107, 0.1);
  border-radius: 0.75rem;
  border: 1px solid rgba(255, 107, 107, 0.2);
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .listings-cards-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .listings-cards-filters {
    width: 100%;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .filter-select {
    flex: 1;
    min-width: 0;
  }
  
  .listings-cards-grid {
    grid-template-columns: 1fr;
  }
  
  .listing-card-list {
    flex-direction: column;
  }
  
  .listing-card-list .listing-image {
    width: 100%;
    height: 180px;
  }
  
  .listing-card-chat {
    flex-direction: column;
  }
  
  .listing-card-chat .listing-image {
    width: 100%;
    height: 120px;
  }
  
  .search-container {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .listings-cards-grid {
    grid-template-columns: repeat(2, 1fr);
  }
} 