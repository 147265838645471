/* GenUICard Styles */
.genui-card {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  margin: 12px 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  transition: all 0.3s ease;
  max-height: 400px;
}

.genui-card.expanded {
  transform: scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  z-index: 10;
}

/* Card types styling */
.memory-card {
  background-image: linear-gradient(to right, rgba(230, 179, 204, 0.05), rgba(255, 204, 179, 0.05));
  border-color: rgba(230, 179, 204, 0.15);
}

.resource-card {
  background-image: linear-gradient(to right, rgba(179, 204, 230, 0.05), rgba(179, 230, 204, 0.05));
  border-color: rgba(179, 204, 230, 0.15);
}

.event-card {
  background-image: linear-gradient(to right, rgba(204, 179, 230, 0.05), rgba(230, 179, 230, 0.05));
  border-color: rgba(204, 179, 230, 0.15);
}

.update-card {
  background-image: linear-gradient(to right, rgba(179, 230, 204, 0.05), rgba(204, 230, 179, 0.05));
  border-color: rgba(179, 230, 204, 0.15);
}

.quest-card {
  background-image: linear-gradient(to right, rgba(255, 215, 0, 0.05), rgba(255, 165, 0, 0.05));
  border-color: rgba(255, 215, 0, 0.15);
}

/* Card header */
.card-header {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.card-icon {
  font-size: 1.2rem;
  margin-right: 12px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-title {
  flex: 1;
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
}

.card-expand-button {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.1);
  border: none;
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.card-expand-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

/* Card body */
.card-body {
  padding: 16px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0.8);
}

.card-description {
  margin: 0 0 12px;
  font-size: 0.95rem;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.7);
}

.card-content {
  font-size: 0.95rem;
  line-height: 1.5;
  margin: 0 0 12px;
  white-space: pre-wrap;
  word-break: break-word;
}

/* Card actions */
.card-actions {
  display: flex;
  gap: 8px;
  padding: 8px 16px 16px;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.card-action-button {
  padding: 6px 12px;
  border-radius: 50px;
  font-size: 0.85rem;
  font-weight: 500;
  cursor: pointer;
  border: none;
  transition: all 0.2s ease;
}

.card-action-button.primary {
  background-color: rgba(230, 179, 204, 0.2);
  color: rgba(230, 179, 204, 1);
}

.card-action-button.primary:hover {
  background-color: rgba(230, 179, 204, 0.3);
}

.card-action-button.secondary {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.8);
}

.card-action-button.secondary:hover {
  background-color: rgba(255, 255, 255, 0.15);
}

.card-action-button.danger {
  background-color: rgba(255, 82, 82, 0.15);
  color: rgba(255, 82, 82, 0.9);
}

.card-action-button.danger:hover {
  background-color: rgba(255, 82, 82, 0.25);
}

.card-action-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Card close button */
.card-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  cursor: pointer;
  z-index: 11;
  transition: all 0.2s ease;
}

.card-close:hover {
  background-color: rgba(0, 0, 0, 0.4);
  color: rgba(255, 255, 255, 0.9);
}

/* Memory card specifics */
.card-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 12px;
}

.card-tag {
  padding: 3px 8px;
  border-radius: 50px;
  font-size: 0.75rem;
  background-color: rgba(230, 179, 204, 0.1);
  color: rgba(230, 179, 204, 0.9);
  white-space: nowrap;
}

.card-date {
  margin-top: 12px;
  text-align: right;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.6);
}

/* Resource card specifics */
.card-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
}

.card-detail-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.card-detail-icon {
  font-size: 0.9rem;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-detail-text {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
}

/* Update card specifics */
.card-update {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 12px;
  margin-top: 12px;
}

.card-update-field {
  font-size: 0.85rem;
  font-weight: 600;
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.7);
}

.card-update-comparison {
  display: flex;
  align-items: center;
  gap: 12px;
}

.card-update-item {
  flex: 1;
}

.card-update-label {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 4px;
}

.card-update-value {
  padding: 8px;
  border-radius: 4px;
  font-size: 0.9rem;
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.9);
  word-break: break-word;
}

.card-update-arrow {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Quest card specifics */
.card-progress {
  margin-top: 16px;
}

.card-progress-bar {
  height: 8px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
}

.card-progress-fill {
  height: 100%;
  background: linear-gradient(to right, #ffd700, #ffa500);
  border-radius: 4px;
  transition: width 0.5s ease;
}

.card-progress-text {
  margin-top: 4px;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.7);
  text-align: right;
}

.card-reward {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(255, 215, 0, 0.1);
}

.card-reward-icon {
  font-size: 1rem;
}

.card-reward-text {
  font-size: 0.9rem;
  color: rgba(255, 215, 0, 0.9);
}

.card-completed {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  padding: 8px;
  border-radius: 8px;
  background-color: rgba(0, 200, 0, 0.1);
}

.card-completed-icon {
  font-size: 1rem;
}

.card-completed-text {
  font-size: 0.9rem;
  color: rgba(0, 200, 0, 0.9);
  font-weight: 600;
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .genui-card {
    margin: 10px 0;
  }
  
  .card-header {
    padding: 10px 12px;
  }
  
  .card-body {
    padding: 12px;
  }
  
  .card-title {
    font-size: 0.95rem;
  }
  
  .card-content, .card-description {
    font-size: 0.9rem;
  }
  
  .card-actions {
    padding: 6px 12px 12px;
  }
  
  .card-action-button {
    padding: 5px 10px;
    font-size: 0.8rem;
  }
}

/* Camp Card Specific Styles */
.camp-card {
  background: rgba(25, 25, 35, 0.85);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 148, 148, 0.2);
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 16px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  max-width: 100%;
  transition: all 0.3s ease;
}

.camp-card:hover {
  border-color: rgba(255, 148, 148, 0.4);
  transform: translateY(-2px);
}

.camp-card .card-summary {
  padding: 8px 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.camp-card .card-location {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.camp-card .location-icon {
  margin-right: 8px;
  font-size: 1rem;
}

.camp-card .location-text {
  color: #f1f1f1;
  font-size: 0.9rem;
}

.camp-card .card-detail-offerings {
  display: flex;
  margin: 12px 0;
}

.camp-card .offerings-list {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-left: 8px;
}

.camp-card .offering-tag {
  background: rgba(255, 148, 148, 0.15);
  color: rgba(255, 148, 148, 0.9);
  font-size: 0.8rem;
  padding: 3px 8px;
  border-radius: 12px;
  white-space: nowrap;
}

.camp-card .events-list {
  list-style-type: none;
  padding-left: 24px;
  margin-top: 8px;
}

.camp-card .events-list li {
  margin-bottom: 6px;
  position: relative;
  font-size: 0.9rem;
}

.camp-card .events-list li:before {
  content: "•";
  position: absolute;
  left: -12px;
  color: rgba(255, 148, 148, 0.8);
}

.camp-card .card-header {
  background: linear-gradient(to right, rgba(255, 148, 148, 0.2), transparent);
}

.camp-cards-container {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Mobile styles */
@media (max-width: 640px) {
  .camp-card {
    margin-bottom: 12px;
  }
  
  .camp-card .card-title {
    font-size: 1rem;
  }
  
  .camp-card .card-description p {
    font-size: 0.85rem;
  }
  
  .camp-card .offering-tag {
    font-size: 0.75rem;
    padding: 2px 6px;
  }
  
  .camp-card .card-action-button {
    font-size: 0.8rem;
    padding: 6px 12px;
  }
}

/* Add styles for the card summary offerings preview */
.camp-card .card-summary-offerings {
  display: flex;
  align-items: center;
  margin-top: 4px;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
}

.camp-card .offerings-icon {
  margin-right: 8px;
  font-size: 1rem;
}

.camp-card .offerings-preview {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 24px);
  color: rgba(255, 148, 148, 0.9);
}

/* Improve the offering tags styling */
.camp-card .offering-tag {
  background: rgba(255, 148, 148, 0.15);
  color: rgba(255, 148, 148, 0.9);
  font-size: 0.8rem;
  padding: 3px 8px;
  border-radius: 12px;
  white-space: nowrap;
  margin: 2px;
  display: inline-block;
  transition: all 0.2s ease;
}

.camp-card .offering-tag:hover {
  background: rgba(255, 148, 148, 0.25);
  transform: translateY(-1px);
}

/* Enhance mobile styles */
@media (max-width: 640px) {
  .camp-card .card-summary {
    padding: 6px 12px;
  }
  
  .camp-card .offerings-preview {
    font-size: 0.8rem;
  }
  
  .camp-card .card-summary-offerings {
    margin-top: 2px;
  }
} 