.interactive-link {
  color: #FF69B4;
  text-decoration: none;
  font-weight: 500;
  padding: 0.1em 0.3em;
  border-radius: 0.2em;
  background-color: rgba(255, 105, 180, 0.1);
  transition: all 0.2s ease;
}

.interactive-link:hover {
  background-color: rgba(255, 105, 180, 0.2);
  color: #FF1493;
  text-decoration: none;
}

.interactive-link:active {
  background-color: rgba(255, 105, 180, 0.3);
}

.bubble-content {
  position: relative;
  transition: all 0.3s ease;
}

.bubble-content.calm {
  background: linear-gradient(135deg, rgba(255, 148, 148, 0.05), rgba(255, 148, 148, 0.1));
}

.bubble-content.excited {
  background: linear-gradient(135deg, rgba(255, 148, 148, 0.1), rgba(255, 148, 148, 0.2));
}

.bubble-content.focused {
  background: linear-gradient(135deg, rgba(255, 148, 148, 0.15), rgba(255, 148, 148, 0.25));
}

.message-text {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  color: #333333;
}

/* Style for user messages specifically */
.talk-bubble.user .message-text,
.talk-bubble.user .markdown-content {
  color: #1a1a1a;
  font-weight: 500;
}

/* Style for agent messages specifically */
.talk-bubble.agent .message-text,
.talk-bubble.agent .markdown-content {
  color: rgba(255, 255, 255, 0.95);
}

.feedback-text {
  font-size: 0.9rem;
  color: rgba(255, 148, 148, 0.9);
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background: rgba(255, 148, 148, 0.1);
}

.choices-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1rem;
}

.choice-button {
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease;
}

.choice-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), transparent);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.choice-button:hover::before {
  opacity: 1;
}

.choice-button.selected {
  transform: scale(0.98);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.choice-button.selected::after {
  content: '✓';
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: currentColor;
  opacity: 0.8;
}

/* Animation variants */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { 
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes expandIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.fade {
  animation: fadeIn 0.3s ease forwards;
}

.slide {
  animation: slideIn 0.3s ease forwards;
}

.expand {
  animation: expandIn 0.3s ease forwards;
}

/* Visual state animations */
@keyframes gentlePulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.02); }
  100% { transform: scale(1); }
}

@keyframes gentleFade {
  0% { opacity: 0.8; }
  50% { opacity: 1; }
  100% { opacity: 0.8; }
}

.gentle-pulse {
  animation: gentlePulse 2s ease-in-out infinite;
}

.gentle-fade {
  animation: gentleFade 3s ease-in-out infinite;
}

/* Responsive design */
@media (max-width: 768px) {
  .choices-container {
    margin-top: 0.5rem;
  }

  .choice-button {
    padding: 0.75rem 1rem;
    font-size: 0.9rem;
  }

  .message-text {
    font-size: 0.9rem;
  }

  .feedback-text {
    font-size: 0.8rem;
  }
}

/* Override styles for the markdown content */
.markdown-content {
  width: 100%;
  line-height: 1.6;
  font-size: 1rem;
}

/* Enhance styling for all markdown elements */
.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
  line-height: 1.3;
  color: #fff;
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.markdown-content h1 { font-size: 1.6rem; }
.markdown-content h2 { font-size: 1.4rem; }
.markdown-content h3 { font-size: 1.2rem; }
.markdown-content h4 { font-size: 1.1rem; }
.markdown-content h5, .markdown-content h6 { font-size: 1rem; }

.markdown-content p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

/* Enhanced styling for strong/bold text - better visual appearance for asterisk formatting */
.markdown-content strong {
  font-weight: 700;
  color: #FFB3BA; /* Light pink color that stands out */
  background: linear-gradient(to right, #FFB3BA, #FFC3A0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: 0.01em;
  padding: 0 0.15em;
}

/* Style for content in user bubbles */
.talk-bubble.user .markdown-content strong {
  background: linear-gradient(to right, rgba(230, 90, 100, 0.9), rgba(255, 120, 130, 0.9));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

/* Style for content in agent bubbles */
.talk-bubble.agent .markdown-content strong {
  background: linear-gradient(to right, #FFB3BA, #FFC3A0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.markdown-content em {
  font-style: italic;
  color: rgba(255, 255, 255, 0.9);
}

.markdown-content em.underscore {
  border-bottom: 1px dotted rgba(255, 255, 255, 0.5);
  padding-bottom: 0.1em;
}

/* Enhance list styling */
.markdown-content ul,
.markdown-content ol {
  margin: 0.75rem 0 1rem 0.75rem;
  padding-left: 1.5rem;
}

.markdown-content ul {
  list-style-type: none;
}

.markdown-content ul li {
  position: relative;
  padding-left: 0.5rem;
  margin-bottom: 0.5rem;
}

.markdown-content ul li::before {
  content: '•';
  position: absolute;
  left: -1rem;
  color: rgba(255, 148, 148, 0.9);
}

.markdown-content ol {
  counter-reset: item;
  list-style-type: none;
}

.markdown-content ol li {
  position: relative;
  padding-left: 0.5rem;
  margin-bottom: 0.5rem;
  counter-increment: item;
}

.markdown-content ol li::before {
  content: counter(item) '.';
  position: absolute;
  left: -1.5rem;
  font-weight: 600;
  color: rgba(255, 148, 148, 0.9);
}

/* Style code blocks and inline code */
.markdown-content pre {
  background-color: rgba(30, 30, 30, 0.6);
  border-radius: 6px;
  padding: 0.75rem 1rem;
  margin: 1rem 0;
  overflow-x: auto;
  border-left: 3px solid rgba(255, 148, 148, 0.5);
}

.markdown-content code {
  font-family: 'SF Mono', 'Roboto Mono', Menlo, Monaco, Consolas, monospace;
  font-size: 0.9em;
  background-color: rgba(30, 30, 30, 0.4);
  padding: 0.2em 0.4em;
  border-radius: 3px;
  color: rgba(255, 255, 255, 0.9);
}

.markdown-content pre code {
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  color: rgba(255, 255, 255, 0.9);
}

/* Enhance blockquotes */
.markdown-content blockquote {
  border-left: 4px solid rgba(255, 148, 148, 0.5);
  padding-left: 1rem;
  margin: 1rem 0;
  color: rgba(255, 255, 255, 0.8);
  font-style: italic;
  background-color: rgba(30, 30, 30, 0.3);
  padding: 0.75rem 1rem;
  border-radius: 0 6px 6px 0;
}

/* Enhance horizontal rule */
.markdown-content hr {
  border: none;
  height: 1px;
  background: linear-gradient(90deg, transparent, rgba(255, 148, 148, 0.5), transparent);
  margin: 1.5rem 0;
}

/* Style tables */
.markdown-content table {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
  overflow-x: auto;
  display: block;
}

.markdown-content table th,
.markdown-content table td {
  padding: 0.5rem 0.75rem;
  border: 1px solid rgba(60, 60, 60, 0.7);
  text-align: left;
}

.markdown-content table th {
  background-color: rgba(40, 40, 40, 0.7);
  font-weight: 600;
}

.markdown-content table tr:nth-child(even) {
  background-color: rgba(30, 30, 30, 0.4);
}

/* Special formatting for numbered elements like the 10 principles */
.markdown-content .numbered-principle {
  display: flex;
  margin-bottom: 0.75rem;
  align-items: flex-start;
}

.markdown-content .principle-number {
  flex: 0 0 2rem;
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 1.1rem;
}

.markdown-content .principle-content {
  flex: 1;
}

.markdown-content .principle-name {
  font-weight: 600;
  color: rgba(255, 148, 148, 0.9);
}

/* Dark mode overrides for agent bubbles */
.talk-bubble.agent .markdown-content * {
  color: rgba(255, 255, 255, 0.95);
}

.talk-bubble.agent .markdown-content a {
  color: rgba(255, 148, 148, 0.9) !important;
  text-decoration: underline;
}

/* User bubble overrides - maintain existing styles */
.talk-bubble.user .markdown-content,
.talk-bubble.user .markdown-content * {
  color: #1a1a1a !important;
  font-weight: 500;
}

.talk-bubble.user .markdown-content a {
  color: #333333 !important;
  text-decoration: underline;
  font-weight: 600;
}

.talk-bubble.user .markdown-content code {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: monospace;
}

/* Mobile-specific optimizations */
@media (max-width: 768px) {
  .markdown-content {
    font-size: 0.95rem;
  }
  
  .markdown-content h1 { font-size: 1.4rem; }
  .markdown-content h2 { font-size: 1.3rem; }
  .markdown-content h3 { font-size: 1.1rem; }
  .markdown-content h4, .markdown-content h5, .markdown-content h6 { font-size: 1rem; }
  
  .markdown-content ul,
  .markdown-content ol {
    padding-left: 1.25rem;
  }
  
  .markdown-content blockquote {
    padding: 0.5rem 0.75rem;
  }
}

/* Add styles for callouts */
.markdown-content .callout {
  margin: 1rem 0;
  padding: 0.75rem;
  border-radius: 6px;
  font-size: 0.95rem;
}

.markdown-content .callout.note,
.markdown-content .callout.info {
  background-color: rgba(30, 64, 175, 0.15);
  border-left: 4px solid rgba(30, 64, 175, 0.4);
}

.markdown-content .callout.tip {
  background-color: rgba(21, 128, 61, 0.15);
  border-left: 4px solid rgba(21, 128, 61, 0.4);
}

.markdown-content .callout.warning {
  background-color: rgba(234, 88, 12, 0.15);
  border-left: 4px solid rgba(234, 88, 12, 0.4);
}

.markdown-content .callout.important {
  background-color: rgba(225, 29, 72, 0.15);
  border-left: 4px solid rgba(225, 29, 72, 0.4);
}

/* Enhance blockquote styling */
.markdown-content blockquote {
  margin: 1.25rem 0;
  padding: 1rem;
  background-color: rgba(30, 30, 30, 0.3);
  border-left: 4px solid rgba(255, 148, 148, 0.5);
  border-radius: 0 6px 6px 0;
  font-style: italic;
  color: rgba(255, 255, 255, 0.85);
}

.markdown-content blockquote p {
  margin: 0;
}

/* Better display for code blocks */
.markdown-content pre {
  position: relative;
  background-color: rgba(30, 30, 30, 0.6);
  border-radius: 6px;
  padding: 1rem;
  margin: 1.25rem 0;
  overflow-x: auto;
  border-left: 3px solid rgba(255, 148, 148, 0.5);
}

.markdown-content pre::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.03), transparent);
  pointer-events: none;
}

/* Enhance the spacing in lists */
.markdown-content ul li:not(:last-child),
.markdown-content ol li:not(:last-child) {
  margin-bottom: 0.5rem;
}

/* Enhance the appearance of emphasis in dark mode */
.talk-bubble.agent .markdown-content em {
  color: rgba(255, 148, 148, 0.85);
  font-style: italic;
}

/* Improve anchor links */
.markdown-content a {
  color: rgba(255, 148, 148, 0.9);
  text-decoration: none;
  border-bottom: 1px dashed rgba(255, 148, 148, 0.5);
  transition: all 0.2s ease;
}

.markdown-content a:hover {
  color: rgba(255, 148, 148, 1);
  border-bottom: 1px solid rgba(255, 148, 148, 0.8);
}

/* Speech bubble improvement */
.talk-bubble {
  position: relative;
  border-radius: 1rem;
  max-width: 90%;
  word-break: break-word;
  overflow-wrap: break-word;
}

.talk-bubble.user {
  background: linear-gradient(135deg, rgba(255, 170, 180, 0.85), rgba(255, 190, 190, 0.85));
  color: #1a1a1a;
  margin-left: auto;
  margin-right: 0;
  /* Remove animation for a cleaner look */
  animation: none;
}

.talk-bubble.agent {
  background-color: var(--agent-bubble-bg, #FFFFFF);
  border: 1px solid var(--agent-bubble-border, #E2E8F0);
  color: var(--agent-bubble-text, #1E293B);
  margin-right: auto;
  margin-left: 0;
  padding-right: 1.5rem;
  max-width: 85%;
}

@media (max-width: 640px) {
  .talk-bubble {
    max-width: 88%;
  }
  
  .talk-bubble.agent {
    max-width: 80%;
  }
}

.talk-bubble svg {
  position: absolute;
  bottom: 0;
  fill: currentColor;
}

.talk-bubble.user svg {
  right: -11px;
  fill: rgba(255, 180, 185, 0.85);
  transform: scaleX(-1);
}

.talk-bubble.agent svg {
  left: -11px;
  fill: var(--agent-bubble-bg, #FFFFFF);
}

.bubble-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.message-content {
  width: 100%;
}

.markdown-content {
  width: 100%;
}

.markdown-content p {
  margin-bottom: 0.75rem;
}

.markdown-content p:last-child {
  margin-bottom: 0;
}

.markdown-content pre {
  background-color: #f1f5f9;
  padding: 1rem;
  border-radius: 0.5rem;
  overflow-x: auto;
  margin: 1rem 0;
}

.markdown-content code {
  font-family: monospace;
  background-color: #f1f5f9;
  padding: 0.125rem 0.25rem;
  border-radius: 0.25rem;
  font-size: 0.875rem;
}

.markdown-content pre code {
  background-color: transparent;
  padding: 0;
}

.feedback-text {
  font-size: 0.875rem;
  color: #64748b;
  padding: 0.5rem;
  background-color: #f8fafc;
  border-radius: 0.5rem;
  border-left: 3px solid #3b82f6;
}

.choices-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.choice-button {
  background-color: #f1f5f9;
  color: #1e293b;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.choice-button:hover {
  background-color: #e2e8f0;
}

.choice-button.selected {
  background-color: #3b82f6;
  color: white;
}

.resource-card {
  background-color: #f8fafc;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 0.75rem 0;
  border-left: 3px solid #3b82f6;
}

.more-details-btn {
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  margin-top: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.more-details-btn:hover {
  background-color: #2563eb;
}

.camp-cards-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

/* Collapsible thinking content styles */
.thinking-collapsible {
  background-color: rgba(30, 30, 37, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  margin: 1rem 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.thinking-collapsible-open {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.thinking-trigger-wrapper {
  padding: 0.75rem 1rem;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  transition: background-color 0.2s ease, color 0.2s ease;
  position: relative;
}

.thinking-trigger-wrapper:hover {
  background-color: rgba(var(--accent-rgb), 0.1);
}

.thinking-trigger-open-wrapper {
  background-color: rgba(255, 105, 180, 0.15);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.thinking-trigger {
  font-size: 0.95rem;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.85);
  display: flex;
  align-items: center;
  width: 100%;
}

.thinking-trigger-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  font-size: 0.7rem;
  color: rgba(255, 105, 180, 0.9);
  transition: transform 0.2s ease;
}

.thinking-trigger-open {
  transform: rotate(0deg);
}

.thinking-content-wrapper {
  padding: 1rem;
  background-color: rgba(30, 30, 40, 0.4);
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  line-height: 1.5;
  overflow-x: auto;
}

.thinking-content-wrapper code {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 0.2em 0.4em;
  border-radius: 0.25rem;
  font-size: 0.85em;
  font-family: monospace;
}

.thinking-content-wrapper pre {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 0.25rem;
  overflow-x: auto;
}

.thinking-content-wrapper pre code {
  background-color: transparent;
  padding: 0;
}

/* Adjust for user color theme */
.talk-bubble.user .thinking-collapsible {
  background-color: rgba(240, 240, 245, 0.1);
  border-color: rgba(0, 0, 0, 0.1);
}

.talk-bubble.user .thinking-trigger {
  color: rgba(0, 0, 0, 0.8);
}

.talk-bubble.user .thinking-content-wrapper {
  background-color: rgba(240, 240, 245, 0.05);
  color: rgba(0, 0, 0, 0.75);
}

/* Mobile responsive adjustments */
@media (max-width: 640px) {
  .thinking-trigger-wrapper {
    padding: 0.6rem 0.8rem;
  }
  
  .thinking-content-wrapper {
    padding: 0.8rem;
    font-size: 0.85rem;
  }
}

/* Fix for bubble layout issues */
.talk-bubble {
  display: flex;
  flex-direction: column;
  max-width: 85%;
}

.talk-bubble.user {
  align-self: flex-end;
  margin-left: auto;
  margin-right: 0;
  padding: 0.75rem 1rem;
}

.talk-bubble.agent {
  align-self: flex-start;
  margin-right: auto;
  margin-left: 0;
  padding: 0.75rem 1rem;
}

@media (max-width: 640px) {
  .talk-bubble {
    max-width: 90%;
  }
  
  .talk-bubble.user, 
  .talk-bubble.agent {
    padding: 0.5rem 0.75rem;
  }
}

/* Dot flashing animation for loading */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #3b82f6;
  color: #3b82f6;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}

.dot-flashing::before,
.dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #3b82f6;
  color: #3b82f6;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #3b82f6;
  color: #3b82f6;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #3b82f6;
  }
  50%, 100% {
    background-color: rgba(59, 130, 246, 0.2);
  }
}

/* CollapsibleThinking integration styles */
.Collapsible {
  margin: 1rem 0;
  border-radius: 0.5rem;
  overflow: hidden;
}

/* Override the default expand/collapse animation for smoother transitions */
.Collapsible__contentOuter {
  transition: height 250ms cubic-bezier(0.4, 0, 0.2, 1);
}

/* Settings toggle for thinking content visibility */
.thinking-settings {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.thinking-toggle {
  margin-left: 0.5rem;
  position: relative;
  width: 40px;
  height: 20px;
}

.thinking-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.thinking-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

.thinking-toggle-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .thinking-toggle-slider {
  background-color: #ff9494;
}

input:checked + .thinking-toggle-slider:before {
  transform: translateX(20px);
}

/* Tooltip styles */
.thinking-tooltip {
  position: relative;
  display: inline-block;
  margin-left: 0.5rem;
  cursor: help;
}

.thinking-tooltip .tooltip-icon {
  width: 16px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #64748b;
  color: white;
  font-size: 10px;
  font-weight: bold;
}

.thinking-tooltip .tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: #334155;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -100px;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 0.8rem;
  line-height: 1.4;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.thinking-tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #334155 transparent transparent transparent;
}

.thinking-tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Ensure Collapsible component styling matches design system */
.Collapsible__trigger {
  display: block;
  position: relative;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Mobile optimizations */
@media (max-width: 640px) {
  .thinking-settings {
    font-size: 0.8rem;
  }
  
  .thinking-toggle {
    width: 36px;
    height: 18px;
  }
  
  .thinking-toggle-slider:before {
    height: 14px;
    width: 14px;
  }
  
  .thinking-tooltip .tooltip-text {
    width: 180px;
    margin-left: -90px;
    font-size: 0.75rem;
  }
}

/* Camp Information Formatting */
.markdown-content .camp-info {
  background-color: rgba(255, 148, 148, 0.08);
  border-radius: 0.75rem;
  padding: 1rem;
  margin: 1rem 0;
  border-left: 3px solid rgba(255, 148, 148, 0.5);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.markdown-content .camp-info strong {
  display: block;
  margin-top: 0.75rem;
  margin-bottom: 0.25rem;
  color: rgba(255, 105, 180, 0.8);
  font-weight: 600;
}

/* Style for camp location and other emoji-prefixed sections */
.markdown-content .camp-info span[class$="-icon"] {
  margin-right: 0.5rem;
  display: inline-block;
}

/* Bulleted list items within camp info */
.markdown-content .camp-info ul {
  margin: 0.5rem 0 0.5rem 1rem;
  padding: 0;
}

.markdown-content .camp-info li {
  list-style-type: none;
  margin-bottom: 0.25rem;
  line-height: 1.4;
}

/* For emoji decorated text (like location, time, etc.) */
.emoji-decorated {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.emoji-decorated .emoji {
  flex-shrink: 0;
  margin-right: 0.5rem;
  font-size: 1.1em;
}

.emoji-decorated .text {
  flex-grow: 1;
}

/* Camp emoji specific styling */
.markdown-content .camp-info 🔥,
.markdown-content .camp-info 📍,
.markdown-content .camp-info ⏰,
.markdown-content .camp-info ✨,
.markdown-content .camp-info 🎪,
.markdown-content .camp-info 🗓️,
.markdown-content .camp-info 📅,
.markdown-content .camp-info 🌟 {
  display: inline-block;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  vertical-align: middle;
}

@media (max-width: 768px) {
  .markdown-content .camp-info {
    padding: 0.75rem;
    margin: 0.75rem 0;
  }
  
  .markdown-content .camp-info strong {
    margin-top: 0.5rem;
  }
}

/* Style camp cards with proper spacing */
.markdown-content .camp-card {
  background: rgba(255, 148, 148, 0.1);
  border-radius: 10px;
  padding: 1rem;
  margin: 1.25rem 0;
  border-left: 3px solid rgba(255, 148, 148, 0.5);
}

.markdown-content .camp-title {
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
  text-align: center;
  background: linear-gradient(45deg, #FFB3BA, #FFC3A0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0.25rem 0;
}

.markdown-content .camp-location {
  margin-bottom: 0.75rem;
  font-weight: 500;
  line-height: 1.4;
}

.markdown-content .camp-offerings {
  margin-bottom: 0.75rem;
  line-height: 1.4;
}

.markdown-content .camp-description {
  line-height: 1.4;
  font-style: italic;
  opacity: 0.9;
}

.markdown-content .camp-name-highlight {
  font-weight: 700;
  background: linear-gradient(45deg, #FFB3BA, #FFC3A0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Ensure each section of camp info has proper spacing */
.markdown-content .camp-card > div {
  padding-bottom: 0.5rem;
}

.markdown-content .camp-card > div:not(:last-child) {
  border-bottom: 1px solid rgba(255, 148, 148, 0.2);
  margin-bottom: 0.5rem;
}

/* Mobile optimization for camp cards */
@media (max-width: 768px) {
  .markdown-content .camp-card {
    padding: 0.75rem;
    margin: 1rem 0;
  }
  
  .markdown-content .camp-title {
    font-size: 1rem;
  }
}

/* Legacy camp info styling for backward compatibility */
.markdown-content .camp-info {
  background: rgba(255, 148, 148, 0.08);
  border-radius: 8px;
  padding: 0.75rem;
  margin: 1rem 0;
  border-left: 2px solid rgba(255, 148, 148, 0.4);
}

/* Style consecutive camp cards */
.markdown-content .camp-card + .camp-card {
  margin-top: 1.5rem;
}

/* Enhance visual appearance of camp cards */
.markdown-content .camp-card {
  position: relative;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.markdown-content .camp-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

/* Add subtle visual separation between cards */
.markdown-content .camp-card:not(:last-child) {
  margin-bottom: 2rem;
}

/* Style standalone camp mentions */
.markdown-content p:has(span.camp-name-highlight) {
  margin: 1rem 0;
}

/* Add these styles for the new message bubble design */
.message-bubble {
  position: relative;
  max-width: 100%;
  border-radius: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  overflow: hidden;
  transition: all 0.3s ease;
}

/* User message styling */
.user-message {
  margin-left: auto;
  border-bottom-right-radius: 0.25rem;
  background: var(--gradient-user);
  color: #1a1a1a;
  font-weight: 500;
}

/* Assistant message styling */
.assistant-message {
  margin-right: auto;
  border-bottom-left-radius: 0.25rem;
  background: rgba(42, 43, 54, 0.8);
  backdrop-filter: blur(8px);
  color: rgba(255, 255, 255, 0.95);
}

/* Message text styling */
.message-text {
  font-size: 1rem;
  line-height: 1.6;
  color: inherit;
  word-break: break-word;
  overflow-wrap: break-word;
}

/* Bubble container layout */
.bubble-container {
  margin: 0.5rem 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 0.75rem;
}

/* Enhanced animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.message-bubble {
  animation: fadeInUp 0.3s ease-out;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .message-bubble {
    font-size: 0.95rem;
    padding: 0.75rem;
  }
  
  .message-text {
    font-size: 0.95rem;
  }
  
  .bubble-container {
    gap: 0.5rem;
  }
}

/* Link styling within messages */
.message-text a {
  color: var(--accent-color);
  text-decoration: underline;
  text-decoration-color: rgba(var(--accent-rgb), 0.4);
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  transition: all 0.2s ease;
}

.message-text a:hover {
  text-decoration-color: rgba(var(--accent-rgb), 0.8);
  text-decoration-thickness: 2px;
}

/* Code block styling */
.message-text pre {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 0.5rem 0;
  overflow-x: auto;
}

.message-text code {
  font-family: 'Menlo', 'Monaco', 'Courier New', monospace;
  font-size: 0.9em;
  padding: 0.2em 0.4em;
  border-radius: 0.25em;
  background-color: rgba(0, 0, 0, 0.1);
}

/* Thinking component styling */
.thinking-collapsible {
  background-color: rgba(30, 30, 37, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  margin: 1rem 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.thinking-trigger-wrapper {
  padding: 0.75rem 1rem;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  transition: background-color 0.2s ease, color 0.2s ease;
  position: relative;
}

.thinking-trigger-wrapper:hover {
  background-color: rgba(var(--accent-rgb), 0.1);
}

.thinking-content-wrapper {
  padding: 1rem;
  background-color: rgba(30, 30, 40, 0.4);
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.9rem;
  line-height: 1.5;
  overflow-x: auto;
}

/* Dark mode adjustments */
@media (prefers-color-scheme: dark) {
  .user-message {
    background: var(--gradient-user);
    color: #1a1a1a;
  }
  
  .assistant-message {
    background-color: rgba(42, 43, 54, 0.8);
    color: rgba(255, 255, 255, 0.95);
  }
}

/* Gradient animations */
.message-bubble {
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
}

@keyframes gradient-shift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Processing indicator */
.assistant-processing {
  padding: 0.5rem 0;
}

.processing-indicator {
  font-weight: 500;
  color: var(--accent-color);
  display: flex;
  align-items: center;
}

.processing-indicator::after {
  content: "";
  width: 12px;
  height: 12px;
  margin-left: 8px;
  background-color: var(--accent-color);
  border-radius: 50%;
  display: inline-block;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    opacity: 0.3;
    transform: scale(0.8);
  }
  50% {
    opacity: 0.8;
    transform: scale(1.1);
  }
  100% {
    opacity: 0.3;
    transform: scale(0.8);
  }
}

/* Camp card styling */
.camp-card {
  background: rgba(var(--accent-rgb), 0.1);
  border-radius: 10px;
  padding: 1rem;
  margin: 1.25rem 0;
  border-left: 3px solid rgba(var(--accent-rgb), 0.5);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.camp-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.camp-title {
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
  text-align: center;
  background: var(--gradient-primary);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0.25rem 0;
}

/* Feedback message styling */
.feedback-message {
  background: rgba(var(--accent-rgb), 0.1);
  border-radius: 0.5rem;
  padding: 0.75rem;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: var(--accent-color);
}

/* Mobile optimizations for camp cards */
@media (max-width: 768px) {
  .camp-card {
    padding: 0.75rem;
    margin: 1rem 0;
  }
  
  .camp-title {
    font-size: 1rem;
  }
}

/* Message bubble base styles with higher specificity */
.bubble-container .message-bubble {
  position: relative;
  max-width: 100%;
  border-radius: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  transition: all 0.3s ease;
  padding: 0.75rem 1rem;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

/* User message styling with higher specificity */
.bubble-container .message-bubble.user-message {
  margin-left: auto;
  border-bottom-right-radius: 0.25rem;
  background: linear-gradient(135deg, rgba(255, 170, 180, 0.85), rgba(255, 190, 190, 0.85));
  color: #1a1a1a;
  font-weight: 500;
  border: none;
  animation: none;
}

/* Assistant message styling with higher specificity */
.bubble-container .message-bubble.assistant-message {
  margin-right: auto;
  border-bottom-left-radius: 0.25rem;
  background: rgba(42, 43, 54, 0.8);
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Override any conflicting styles */
.bubble-container .message-bubble.user-message,
.bubble-container .message-bubble.assistant-message {
  background-color: rgba(42, 43, 54, 0.8) !important;
  color: #ffffff !important;
}

/* Override only assistant message styles - user message handled separately */
.bubble-container .message-bubble.assistant-message {
  background-color: rgba(42, 43, 54, 0.8) !important;
  color: #ffffff !important;
}

/* Container layout with higher specificity */
.chat-messages .bubble-container {
  margin: 0.5rem 0;
  width: 100%;
  display: flex;
  align-items: flex-end;
  gap: 0.75rem;
}

/* Enhanced animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.bubble-container .message-bubble {
  animation: fadeInUp 0.3s ease-out;
}

/* Mobile optimizations with higher specificity */
@media (max-width: 768px) {
  .bubble-container .message-bubble {
    font-size: 0.875rem;
    padding: 0.75rem;
  }
  
  .bubble-container .message-bubble .message-text {
    font-size: 0.875rem;
  }
  
  .chat-messages .bubble-container {
    gap: 0.5rem;
  }
}

/* Override any conflicting styles */
.bubble-container .message-bubble.user-message,
.bubble-container .message-bubble.assistant-message {
  background-color: rgba(42, 43, 54, 0.8) !important;
  color: #ffffff !important;
}

.bubble-container .message-bubble.user-message {
  margin-left: auto;
  border-bottom-right-radius: 0.25rem;
  background: linear-gradient(135deg, rgba(255, 170, 180, 0.85), rgba(255, 190, 190, 0.85));
  color: #1a1a1a;
  font-weight: 500;
  border: none;
  animation: none;
}

/* Ensure proper text contrast */
.bubble-container .message-bubble.user-message .message-text {
  color: #ff9494 !important;
}

.bubble-container .message-bubble.assistant-message .message-text {
  color: #ffffff !important;
}

/* Update link colors to match theme */
.message-text a {
  color: rgba(255, 105, 180, 0.9) !important;
  text-decoration: underline;
  text-decoration-color: rgba(255, 105, 180, 0.4);
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  transition: all 0.2s ease;
}

.message-text a:hover {
  text-decoration-color: rgba(255, 105, 180, 0.8);
  text-decoration-thickness: 2px;
}

/* Update code block styling */
.message-text pre {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 0.5rem 0;
  overflow-x: auto;
  border-left: 3px solid rgba(255, 105, 180, 0.5);
}

.message-text code {
  font-family: 'Menlo', 'Monaco', 'Courier New', monospace;
  font-size: 0.9em;
  padding: 0.2em 0.4em;
  border-radius: 0.25em;
  background-color: rgba(0, 0, 0, 0.1);
}

/* Update processing indicator color */
.processing-indicator {
  font-weight: 500;
  color: rgba(255, 105, 180, 0.9);
  display: flex;
  align-items: center;
}

.processing-indicator::after {
  content: "";
  width: 12px;
  height: 12px;
  margin-left: 8px;
  background-color: rgba(255, 105, 180, 0.9);
  border-radius: 50%;
  display: inline-block;
  animation: pulse 1.5s infinite ease-in-out;
}

/* Update camp card styling */
.camp-card {
  background: rgba(255, 105, 180, 0.1);
  border-radius: 10px;
  padding: 1rem;
  margin: 1.25rem 0;
  border-left: 3px solid rgba(255, 105, 180, 0.5);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.camp-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.camp-title {
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
  text-align: center;
  background: linear-gradient(45deg, #FFB3BA, #FFC3A0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0.25rem 0;
}

/* Update feedback message styling */
.feedback-message {
  background: rgba(255, 105, 180, 0.1);
  border-radius: 0.5rem;
  padding: 0.75rem;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: rgba(255, 105, 180, 0.9);
}

.bubble-container .message-bubble.user-message .message-text {
  color: #1a1a1a !important;
}

/* Add new system-message class */
.system-message {
  @apply bg-[rgba(87,93,120,0.98)] text-white rounded-3xl rounded-bl-md py-2.5 px-4 shadow-md;
  border: 1px solid rgba(150, 150, 200, 0.2);
} 