/* Header Container */
.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--header-height);
  background: var(--background-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--side-padding);
  z-index: 50;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px); /* For Safari */
}

/* Left Icons Section */
.left-icons,
.right-icons {
  display: flex;
  gap: 0.8rem;
  align-items: center;
}

/* Icon Buttons */
.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.2s ease;
}

.icon-button:hover {
  background: var(--background-hover, rgba(255, 255, 255, 0.1));
  transform: scale(1.1);
}

.icon-button.active {
  background: var(--background-active, rgba(255, 255, 255, 0.2));
}

/* Slider Container */
.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-grow: 1;
  max-width: 400px;
  padding: 0.4rem;
  background-color: var(--background-soft, #1e1e1e);
  border-radius: 9999px;
  overflow: hidden;
  margin: 0 auto; /* Center the slider container */
}

/* Sliding Pill with Dynamic Gradient */
.slider-pill {
  position: absolute;
  top: 0.4rem;
  height: calc(100% - 0.8rem);
  width: calc(50% - 0.4rem);
  border-radius: 9999px;
  z-index: 0;
  transition: all 0.3s ease;
  background: linear-gradient(-45deg, #ffb88c, #ff9a9e, #fad0c4, #ffd1ff);
  background-size: 400% 400%;
  animation: gradient-shift 15s ease infinite;
}

@keyframes gradient-shift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.slider-pill.active-chat {
  left: 0.4rem;
}

.slider-pill.active-discover {
  left: calc(50% + 0.2rem);
}

/* Slider Buttons */
.slider-button {
  all: unset;
  position: relative;
  z-index: 1;
  font-size: 1rem;
  font-family: 'Modulus Pro', sans-serif;
  font-weight: 700;
  color: white;
  cursor: pointer;
  line-height: 2rem;
  width: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Add specific constraints for the logo image */
.slider-button img {
  width: 2.5rem !important;
  height: 2.5rem !important;
  min-width: 2.5rem !important;
  min-height: 2.5rem !important;
  max-width: 2.5rem !important;
  max-height: 2.5rem !important;
  object-fit: contain !important;
}

/* Active button - dark text for better contrast on gradient */
.slider-button.active {
  color: white;
  transition: color 0.3s ease;
}

/* Inactive button - light text */
.slider-button:not(.active) {
  color: #fff; /* Light text for inactive button */
  transition: color 0.3s ease;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .header-container {
    padding: 0.8rem 1rem;
  }

  .left-icons,
  .right-icons {
    gap: 0.5rem;
  }

  .slider-container {
    max-width: 300px;
  }

  .slider-button {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .header-container {
    padding: 0.6rem;
  }

  .left-icons,
  .right-icons {
    gap: 0.4rem;
  }

  .slider-container {
    max-width: 250px;
  }

  .slider-button {
    font-size: 0.8rem;
  }

  .icon-button {
    padding: 0.4rem;
  }
}

/* Mobile Bottom Navigation Bar - Enhanced iOS compatibility */
@media screen and (max-width: 767px) {
  .header-container {
    position: fixed !important;
    top: auto !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
    border-bottom: none !important;
    height: var(--footer-height, 4rem) !important;
    padding: 0.5rem var(--side-padding, 1rem) !important;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1) !important;
    z-index: 9999 !important; /* Highest z-index to ensure visibility */
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    background-color: var(--background-primary, #0F0F0F) !important;
    backdrop-filter: blur(8px) !important;
    -webkit-backdrop-filter: blur(8px) !important; /* For Safari */
    /* iOS-specific fix for safe areas */
    padding-bottom: calc(0.5rem + env(safe-area-inset-bottom, 20px)) !important;
    margin-bottom: 0 !important;
  }
  
  /* Mobile-specific styles for the header components */
  .mobile-slider-container {
    max-width: 150px !important;
    padding: 0.3rem !important;
  }
  
  /* Mobile specific pill adjustments */
  .mobile-slider-container .slider-pill {
    top: 0.3rem !important;
    height: calc(100% - 0.6rem) !important;
    width: calc(50% - 0.3rem) !important;
  }
  
  .mobile-slider-container .slider-pill.active-chat {
    left: 0.3rem !important;
  }
  
  .mobile-slider-container .slider-pill.active-discover {
    left: calc(50% + 0.15rem) !important;
  }
  
  .mobile-left-icons,
  .mobile-right-icons {
    gap: 0.3rem !important;
    display: flex !important;
    align-items: center !important;
  }
  
  /* Make icons slightly smaller on mobile */
  .header-container .icon-button {
    padding: 0.3rem !important;
  }
  
  /* Adjust slider buttons for mobile */
  .slider-button {
    line-height: 1.5rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  
  .slider-button img {
    width: 2rem !important;
    height: 2rem !important;
    min-width: 2rem !important;
    min-height: 2rem !important;
    max-width: 2rem !important;
    max-height: 2rem !important;
  }
}

/* Mobile body adjustments - Enhanced iOS compatibility */
@media screen and (max-width: 767px) {
  /* Set padding to account for the bottom bar */
  body {
    padding-top: 0 !important;
    padding-bottom: calc(var(--footer-height, 4rem) + env(safe-area-inset-bottom, 20px)) !important;
    /* iOS viewport height fix */
    min-height: -webkit-fill-available !important;
  }
  
  /* Chat container should have overflow hidden */
  .chat-container {
    overflow: hidden !important;
  }
  
  /* Components that need scrolling */
  .profile-container,
  .add-entry-container,
  .loobricate-profile-container {
    overflow-y: auto !important;
    -webkit-overflow-scrolling: touch !important;
    padding-bottom: calc(var(--footer-height, 4rem) + env(safe-area-inset-bottom, 20px) + 1rem) !important;
    margin-bottom: 0 !important;
    height: 100% !important;
  }
  
  /* Adjust main container spacing */
  .main-container,
  .modal,
  .app-content,
  .content-container {
    margin-top: 0 !important;
    padding-bottom: calc(var(--footer-height, 4rem) + env(safe-area-inset-bottom, 20px) + 1rem) !important;
  }
  
  /* Used to mark components that should have scrolling */
  .scroll-enabled {
    overflow-y: auto !important;
    -webkit-overflow-scrolling: touch !important;
    overscroll-behavior-y: contain !important;
  }
  
  /* Used to mark components that should not have scrolling */
  .no-scroll {
    overflow: hidden !important;
  }
  
  /* Make sure the bottom bar is visible in all circumstances */
  .main-content {
    padding-bottom: calc(var(--footer-height, 4rem) + env(safe-area-inset-bottom, 20px) + 1rem) !important;
  }
  
  /* Use the viewport height variable to size elements correctly */
  .app-layout, .app-content {
    height: calc(100 * var(--vh)) !important;
  }
  
  /* Fix for iOS Safari address bar */
  html {
    height: -webkit-fill-available !important;
  }
  
  body {
    min-height: -webkit-fill-available !important;
  }
}

/* Fix for bottom navigation on specific iOS devices */
@supports (padding: max(0px)) {
  .header-container,
  nav.fixed-bottom {
    padding-bottom: max(0.5rem, env(safe-area-inset-bottom, 20px)) !important;
  }
  
  .profile-container,
  .add-entry-container,
  .loobricate-profile-container,
  .main-container,
  .modal,
  .app-content,
  .content-container {
    padding-bottom: max(4rem, calc(var(--footer-height, 4rem) + env(safe-area-inset-bottom, 20px))) !important;
  }
}

.main-container,
.app-content,
.content-container {
  position: relative;
  z-index: 1;
}

.header-adjacent {
  padding-top: var(--header-height);
}

/* Ensure CompanionManager has higher z-index when open */
.fixed.inset-0 {
  z-index: 9999 !important;
}

