.add-entry-container {
  composes: modal-component;
  display: flex;
  flex-direction: column;
  background: var(--background-primary);
  border-radius: 1rem;
  overflow: hidden;
}

.loobricate-profile-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #1a1b26;
  position: relative;
  padding-bottom: 2rem;
}

.loobricate-profile-box {
  width: 100%;
  background: #1e1e28;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  margin-bottom: 2rem;
}

/* Profile Header */
.profile-header {
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
  background: transparent !important;
  box-shadow: none !important;
}

.profile-header-section {
  position: relative;
  width: 100%;
  background-color: #16161e;
  border-radius: 0 0 1rem 1rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  margin-bottom: 5rem;
}

.cover-photo-section {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
  border-radius: 0 0 1rem 1rem;
}

.cover-photo-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.cover-photo-section:hover img {
  transform: scale(1.02);
}

.cover-photo-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #2b2c3d 0%, #191a29 100%);
  font-size: 3rem;
  color: #6c7293;
}

.profile-photo-wrapper {
  position: absolute;
  bottom: -60px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.profile-photo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #1a1b26;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  background-color: #16161e;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-photo:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4);
}

.profile-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-photo-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2d2e3f;
  font-size: 3rem;
  color: #6c7293;
}

/* Main Content */
.main-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

.loobricate-header {
  text-align: center;
  margin-bottom: 2rem;
}

.loobricate-header h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #ffffff;
  position: relative;
  display: inline-block;
}

.loobricate-header h1::after {
  content: '';
  position: absolute;
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(138, 180, 248, 0.8) 50%, rgba(255,255,255,0) 100%);
}

.description {
  max-width: 700px;
  margin: 1rem auto;
  color: #a9b1d6;
  line-height: 1.6;
  font-size: 1.1rem;
}

/* Vibe Section */
.vibe-section {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.visualization-section {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.vibe-display {
  width: 100%;
  aspect-ratio: 16/9;
  min-height: 200px;
  position: relative;
  border-radius: 0.75rem;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
}

.vibe-entity-wrapper {
  position: absolute;
  inset: 0;
}

.vibe-label {
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  padding: 0.375rem 0.75rem;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  border-radius: 0.5rem;
  font-size: 0.8125rem;
  color: var(--text-primary);
  z-index: 1;
}

.comparison-mode {
  display: flex;
  gap: 1.5rem;
}

.comparison-mode .vibe-display {
  flex: 1;
}

/* Info Section */
.info-section {
  background-color: #1e1f2e;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  height: fit-content;
}

.info-section h3 {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
  color: #c0caf5;
  border-bottom: 1px solid #2c2d41;
  padding-bottom: 0.5rem;
}

.info-item {
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
}

.info-item-icon {
  margin-right: 0.75rem;
  color: #7aa2f7;
  font-size: 1.25rem;
  margin-top: 0.25rem;
}

.info-item-content {
  flex: 1;
}

.info-item-label {
  font-size: 0.85rem;
  color: #a9b1d6;
  margin-bottom: 0.25rem;
}

.info-item-value {
  font-size: 1rem;
  color: #c0caf5;
  word-break: break-word;
}

/* Tags Display */
.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.tag {
  background-color: #2b2c3d;
  border-radius: 1rem;
  padding: 0.35rem 0.75rem;
  font-size: 0.85rem;
  color: #7aa2f7;
  transition: all 0.2s ease;
}

.tag:hover {
  background-color: #3b3c4d;
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Listings Section - Redesigned with softer pastel colors */
.listings-section {
  background-color: rgba(30, 31, 46, 0.8);
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.05);
  margin-top: 2rem;
}

.listings-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
  gap: 1rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  padding-bottom: 1rem;
}

.listings-title {
  font-size: 1.5rem;
  color: #f0f0f0;
  display: flex;
  align-items: baseline;
  position: relative;
}

.listings-title::after {
  content: '';
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  width: 50%;
  height: 2px;
  background: linear-gradient(45deg, rgba(253, 164, 175, 0.7), rgba(253, 186, 116, 0.7));
  border-radius: 2px;
}

.listings-count {
  font-size: 1rem;
  color: rgba(253, 164, 175, 0.9);
  font-weight: normal;
  margin-left: 0.75rem;
}

.listings-filters {
  display: flex;
  gap: 0.75rem;
  flex-wrap: wrap;
  align-items: center;
}

.filter-select {
  background-color: rgba(43, 44, 61, 0.8);
  border: 1px solid rgba(253, 164, 175, 0.2);
  border-radius: 0.5rem;
  color: #f0f0f0;
  padding: 0.5rem 0.75rem;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.filter-select:hover, .filter-select:focus {
  background-color: rgba(59, 60, 77, 0.8);
  border-color: rgba(253, 164, 175, 0.4);
  outline: none;
}

.filter-select option {
  background-color: #1e1f2e;
  color: #f0f0f0;
}

/* Listings Grid - Improved with softer colors */
.listings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.listing-card {
  background-color: rgba(36, 39, 58, 0.7);
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.listing-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  border-color: rgba(253, 164, 175, 0.3);
}

.listing-image {
  height: 180px;
  overflow: hidden;
  position: relative;
}

.listing-image::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  background: linear-gradient(to top, rgba(36, 39, 58, 0.7), transparent);
  pointer-events: none;
}

.listing-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.listing-card:hover .listing-image img {
  transform: scale(1.05);
}

.listing-image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, rgba(43, 44, 61, 0.8) 0%, rgba(25, 26, 41, 0.8) 100%);
  font-size: 2.5rem;
  color: rgba(253, 164, 175, 0.3);
}

.listing-content {
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.listing-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.75rem;
}

.listing-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #f0f0f0;
  margin-right: 0.5rem;
}

.listing-type {
  font-size: 0.75rem;
  background: linear-gradient(45deg, rgba(253, 164, 175, 0.8), rgba(253, 186, 116, 0.8));
  color: #1a1b26;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  white-space: nowrap;
}

.listing-meta {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.75rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
}

.listing-meta span {
  display: flex;
  align-items: center;
  gap: 0.35rem;
}

.listing-description {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 1rem;
  font-size: 0.95rem;
  line-height: 1.5;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  flex: 1;
}

.listing-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  padding-top: 0.75rem;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}

.listing-date {
  font-size: 0.85rem;
  color: rgba(255, 255, 255, 0.6);
}

.listing-view-btn {
  background: linear-gradient(45deg, rgba(253, 164, 175, 0.8), rgba(253, 186, 116, 0.8));
  background-size: 200% 200%;
  animation: gradient-shift 5s ease infinite;
  border: none;
  border-radius: 0.5rem;
  color: #1a1b26;
  padding: 0.4rem 0.75rem;
  font-size: 0.85rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.listing-view-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(253, 164, 175, 0.3);
  opacity: 0.9;
}

/* Improved Pagination */
.pagination-container {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}

.pagination-button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(43, 44, 61, 0.8);
  color: #f0f0f0;
  border: 1px solid rgba(253, 164, 175, 0.2);
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 600;
}

.pagination-button:hover {
  background-color: rgba(59, 60, 77, 0.8);
  border-color: rgba(253, 164, 175, 0.4);
  transform: translateY(-2px);
}

.pagination-button.active {
  background: linear-gradient(45deg, rgba(253, 164, 175, 0.8), rgba(253, 186, 116, 0.8));
  background-size: 200% 200%;
  animation: gradient-shift 5s ease infinite;
  color: #1a1b26;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border: none;
}

/* Empty State */
.listings-empty {
  padding: 3rem 1rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}

.listings-empty h3 {
  font-size: 1.5rem;
  color: #f0f0f0;
  margin-bottom: 1rem;
}

.listings-empty p {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 1.5rem;
}

.add-listing-btn {
  background: linear-gradient(45deg, rgba(253, 164, 175, 0.8), rgba(253, 186, 116, 0.8));
  background-size: 200% 200%;
  animation: gradient-shift 5s ease infinite;
  border: none;
  border-radius: 0.5rem;
  color: #1a1b26;
  padding: 0.75rem 1.25rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
}

.add-listing-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(253, 164, 175, 0.3);
  opacity: 0.9;
}

/* Loading State */
.listings-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 3rem 0;
}

.loading-spinner {
  border: 3px solid rgba(253, 164, 175, 0.2);
  border-top: 3px solid rgba(253, 164, 175, 0.8);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .listings-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .listings-filters {
    width: 100%;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .filter-select {
    flex: 1;
    min-width: 0;
  }
  
  .listings-grid {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .listings-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Keep essential modal overlay styles */
.modal-overlay {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal-overlay.visible {
  opacity: 1;
  visibility: visible;
}

/* Close Button */
.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: rgba(26, 27, 38, 0.7);
  border: none;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #c0caf5;
  font-size: 1.25rem;
  cursor: pointer;
  z-index: 10;
  backdrop-filter: blur(5px);
  transition: all 0.2s ease;
}

.close-button:hover {
  background-color: rgba(26, 27, 38, 0.9);
  transform: rotate(90deg);
  color: #7aa2f7;
}

/* Categories for better organization */
.listings-by-category {
  margin-top: 2rem;
}

.category-section {
  margin-bottom: 3rem;
}

.category-title {
  font-size: 1.3rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 1.5rem;
  position: relative;
  padding-left: 1rem;
  border-left: 3px solid #FDA4AF;
}

/* Main content structure */
.responsive-sections {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  margin-top: 2rem;
}

.load-more-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  padding: 1rem;
}

.load-more-btn {
  padding: 0.75rem 1.5rem;
  background: linear-gradient(135deg, rgba(255, 180, 143, 0.2), rgba(255, 126, 121, 0.2));
  border: 1px solid rgba(255, 180, 143, 0.3);
  border-radius: 8px;
  color: var(--text-primary);
  font-size: 0.9375rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.load-more-btn:hover {
  background: linear-gradient(135deg, rgba(255, 180, 143, 0.3), rgba(255, 126, 121, 0.3));
  transform: translateY(-1px);
}

.load-more-btn:active {
  transform: translateY(0);
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
  backdrop-filter: blur(4px);
}

.modal-overlay.visible {
  opacity: 1;
  visibility: visible;
}

.login-form {
  background: var(--background-primary);
  padding: 2rem;
  border-radius: 1rem;
  width: 90%;
  max-width: 400px;
  position: relative;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.form-header h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-primary);
  margin: 0;
}

.login-form-subtitle {
  font-size: 1rem;
  color: var(--text-secondary);
  margin-bottom: 1.5rem;
  text-align: center;
}

.login-form-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.input-group {
  position: relative;
}

.input-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: var(--text-secondary);
  font-size: 1.25rem;
}

.form-input.with-icon {
  padding-left: 3rem;
}

.form-input {
  width: 100%;
  padding: 0.75rem 1rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.5rem;
  color: var(--text-primary);
  font-size: 1rem;
  transition: all 0.2s ease;
}

.form-input:focus {
  outline: none;
  border-color: rgba(255, 180, 143, 0.5);
  background: rgba(255, 255, 255, 0.08);
}

.error-container {
  background: rgba(255, 0, 0, 0.1);
  border: 1px solid rgba(255, 0, 0, 0.2);
  padding: 0.75rem;
  border-radius: 0.5rem;
  margin-top: 0.5rem;
}

.error-message {
  color: #ff6b6b;
  font-size: 0.875rem;
  margin: 0;
}

.submit-button {
  width: 100%;
  padding: 0.875rem;
  background: linear-gradient(135deg, #ffb48f, #ff7e79);
  border: none;
  border-radius: 0.5rem;
  color: #1a1a1a;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 0.5rem;
}

.submit-button:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

.submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .login-form {
    padding: 1.5rem;
    width: 95%;
  }

  .form-header h2 {
    font-size: 1.25rem;
  }

  .submit-button {
    padding: 0.75rem;
  }
}

/* Ensure proper z-index stacking */
.modal-overlay {
  z-index: 1100;
}

.login-form .close-button {
  position: static;
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border: none;
  color: var(--text-primary);
  cursor: pointer;
  transition: all 0.2s ease;
}

.login-form .close-button:hover {
  background: rgba(255, 255, 255, 0.2);
  transform: scale(1.1);
}

/* Ensure proper iOS safe area support */
@supports (height: 100dvh) {
  .loobricate-profile-container {
    height: 100dvh;
  }
}

@supports (padding: env(safe-area-inset-top)) {
  .loobricate-profile-container {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }
}

/* Admin login section */
.admin-login-section {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.admin-login-button {
  background-color: #2b2c3d;
  border: 1px solid #3b3c4d;
  border-radius: 0.5rem;
  color: #a9b1d6;
  padding: 0.75rem 1.25rem;
  font-size: 0.95rem;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.admin-login-button:hover {
  background-color: #3b3c4d;
  color: #7aa2f7;
  border-color: #7aa2f7;
}

.admin-login-icon {
  font-size: 1rem;
}

/* Add these specific overrides */
.main-content {
  overflow-y: visible;
  padding-bottom: 2rem;
}

.profile-section {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.admin-login-button {
  width: 100%;
  padding: 1rem;
  background: linear-gradient(135deg, #ffb48f, #ff7e79);
  border: none;
  border-radius: 8px;
  color: #1a1a1a;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

/* Fix container heights */
.profile-container {
  height: auto;
  overflow: visible;
  padding: 1rem;
}

.profile-box {
  display: block;
  height: auto;
}

/* Adjust main content scrolling */
.main-content {
  flex: 1;
  overflow-y: visible;
  padding: 1rem;
  background: #222;
  border-radius: 12px;
  margin-top: 1rem;
}

/* Ensure sections have proper spacing */
.profile-section {
  margin-bottom: 2rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 12px;
  padding: 1.5rem;
}

/* Fix modal positioning */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;
}

.login-form {
  position: relative;
  z-index: 1001;
  max-width: 400px;
  margin: 2rem auto;
}

/* Mobile-first responsive adjustments */
.profile-container {
  height: auto;
  overflow: visible;
  padding: 1rem;
}

.profile-box {
  display: block;
  height: auto;
}

.main-content {
  flex: 1;
  overflow-y: visible;
  padding: 1rem;
  background: #222;
  border-radius: 12px;
  margin-top: 1rem;
}

/* Listings grid */
.listings-grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
}

/* Admin button mobile styling */
.admin-login-section {
  position: sticky;
  bottom: 0;
  background: #222;
  padding: 1rem;
  margin: 0 -1rem -1rem;
  border-top: 1px solid rgba(255,255,255,0.1);
}

.admin-login-button {
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.admin-login-icon {
  flex-shrink: 0;
}

.admin-login-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Mobile optimizations */
@media (max-width: 480px) {
  .profile-photo-wrapper {
    margin-top: -40px;
    padding-left: 1rem;
  }
  
  .profile-photo {
    width: 80px;
    height: 80px;
  }

  .loobricate-header h1 {
    font-size: 1.5rem;
  }

  .listings-grid {
    grid-template-columns: 1fr;
  }
}

/* Tablet+ styles */
@media (min-width: 768px) {
  .profile-container {
    padding: 2rem;
  }
  
  .main-content {
    padding: 2rem;
    margin-top: 2rem;
  }
  
  .admin-login-section {
    position: static;
    margin: 0;
    padding: 0;
    border-top: none;
  }
}

.full-screen-container {
  position: fixed;
  inset: 0;
  z-index: 50;
  background: var(--background-primary);
  overflow-y: auto;
  height: 100%;
  min-height: 100%;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 2000;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top-color: var(--brand-peach);
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.loobricate-profile-content {
  position: relative;
  width: 100%;
  max-width: 64rem;
  margin: 0 auto;
  min-height: 100vh;
  padding-bottom: 5rem;
}

/* Loading overlay */
.fullscreen-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1001;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  border-top-color: var(--brand-primary);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Admin panel container */
.loobricate-admin-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--background-primary);
  padding: 2rem;
  overflow: auto;
  z-index: 1001;
}

/* Modal container - consistent across all entry points */
.full-screen-container {
  position: fixed;
  inset: 0;
  z-index: 50;
  background: var(--background-primary);
  overflow-y: auto;
  height: 100%;
  min-height: 100%;
}

/* Main content wrapper */
.loobricate-profile-content {
  position: relative;
  width: 100%;
  max-width: 64rem;
  margin: 0 auto;
  min-height: 100vh;
  padding-bottom: 5rem;
}

/* Container for all profile content */
.loobricate-profile-container {
  width: 100%;
  background: var(--background-secondary);
  border-radius: 0;
  overflow: visible;
}

/* Header section with photos */
.profile-header-section {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.cover-photo-section {
  width: 100%;
  height: 12rem;
  position: relative;
  overflow: hidden;
}

.cover-photo-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-photo-wrapper {
  position: absolute;
  bottom: 0;
  left: 1rem;
  transform: translateY(50%);
}

.profile-photo {
  width: 5rem;
  height: 5rem;
  border-radius: 0.75rem;
  border: 4px solid var(--background-primary);
  background: var(--background-primary);
  overflow: hidden;
}

/* Main content area */
.main-content {
  padding: 1rem;
  padding-top: 4rem;
  padding-bottom: 2rem;
  position: relative;
}

/* Close button */
.close-button {
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 50;
  background: rgba(34, 34, 34, 0.8);
  backdrop-filter: blur(4px);
  border-radius: 9999px;
  padding: 0.5rem;
  transition: all 0.2s ease;
}

.close-button:hover {
  background: rgba(34, 34, 34, 0.9);
}

/* Responsive adjustments */
@media (min-width: 768px) {
  .loobricate-profile-container {
    margin: 2rem 1rem;
    border-radius: 0.5rem;
  }

  .cover-photo-section {
    height: 16rem;
  }

  .profile-photo {
    width: 7rem;
    height: 7rem;
  }

  .main-content {
    padding: 1.5rem;
    padding-top: 4rem;
  }
}

/* Ensure proper iOS safe area support */
@supports (padding: env(safe-area-inset-top)) {
  .full-screen-container {
    padding-top: env(safe-area-inset-top);
    padding-bottom: env(safe-area-inset-bottom);
  }
}

/* Main view container styles */
.main-view-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: var(--background-primary, #121212);
  color: var(--text-primary, #ffffff);
}

.loobricate-profile-content.main-view {
  position: relative;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 80px; /* Space for footer */
}

/* Categorized listings styles */
.listings-by-category {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.category-section {
  margin-bottom: 1.5rem;
}

.category-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

/* Responsive adjustments for main view */
@media (max-width: 768px) {
  .loobricate-profile-content.main-view {
    padding: 0;
  }
  
  .loobricate-profile-container {
    padding: 0;
  }
  
  .main-content {
    padding: 1rem;
  }
  
  .category-section {
    margin-bottom: 1rem;
  }
}

/* Ensure the listings grid works well in the main view */
.main-view-container .listings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1rem;
}

/* Ensure the profile header looks good in main view */
.main-view-container .profile-header-section {
  position: relative;
  height: 200px;
  overflow: hidden;
}

.main-view-container .cover-photo-section {
  height: 100%;
  width: 100%;
}

.main-view-container .profile-photo-wrapper {
  position: absolute;
  bottom: -40px;
  left: 2rem;
  z-index: 10;
}

.main-view-container .profile-photo {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid var(--background-primary, #121212);
}

/* Adjust main content padding to account for profile photo */
.main-view-container .main-content {
  padding-top: 3rem;
}

/* Search input styling */
.search-container {
  position: relative;
  flex: 1;
  max-width: 300px;
  margin-right: 0.5rem;
}

.search-input {
  width: 100%;
  background-color: rgba(43, 44, 61, 0.8);
  border: 1px solid rgba(253, 164, 175, 0.2);
  border-radius: 0.5rem;
  color: #f0f0f0;
  padding: 0.5rem 0.75rem;
  font-size: 0.9rem;
  transition: all 0.2s ease;
}

.search-input:focus {
  outline: none;
  border-color: rgba(253, 164, 175, 0.4);
  background-color: rgba(59, 60, 77, 0.8);
  box-shadow: 0 0 0 2px rgba(253, 164, 175, 0.1);
}

.search-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/* View toggle button */
.view-toggle-btn {
  background-color: rgba(43, 44, 61, 0.8);
  border: 1px solid rgba(253, 164, 175, 0.2);
  border-radius: 0.5rem;
  color: #f0f0f0;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.view-toggle-btn:hover {
  background-color: rgba(59, 60, 77, 0.8);
  border-color: rgba(253, 164, 175, 0.4);
  transform: translateY(-2px);
}

/* List view styling */
.listings-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.listings-list .listing-card {
  display: flex;
  flex-direction: row;
  height: auto;
  min-height: 160px;
}

.listings-list .listing-image {
  width: 200px;
  height: auto;
  min-height: 160px;
  flex-shrink: 0;
}

.listings-list .listing-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.listings-list .listing-description {
  -webkit-line-clamp: 2;
}

.listings-list .listing-header {
  flex-wrap: wrap;
}

/* Responsive adjustments for list view */
@media (max-width: 767px) {
  .listings-list .listing-card {
    flex-direction: column;
  }
  
  .listings-list .listing-image {
    width: 100%;
    height: 180px;
  }
  
  .search-container {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}

/* Ensure the grid view still works properly */
.listings-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.login-modal {
  background: var(--background-color, #1a1a1a);
  border-radius: 8px;
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login-modal .close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--text-color, #ffffff);
  opacity: 0.7;
  transition: opacity 0.2s;
}

.login-modal .close-button:hover {
  opacity: 1;
}

.login-modal h2 {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--text-color, #ffffff);
}

.login-modal .form-group {
  margin-bottom: 1rem;
}

.login-modal label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--text-color, #ffffff);
  font-size: 0.875rem;
}

.login-modal input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid var(--border-color, #333);
  border-radius: 4px;
  background: var(--input-background, #2a2a2a);
  color: var(--text-color, #ffffff);
  font-size: 1rem;
}

.login-modal input:focus {
  outline: none;
  border-color: var(--primary-color, #ff6b6b);
  box-shadow: 0 0 0 2px rgba(255, 107, 107, 0.2);
}

.login-modal .error-message {
  color: var(--error-color, #ff4444);
  font-size: 0.875rem;
  margin-bottom: 1rem;
}

.login-modal .submit-button {
  width: 100%;
  padding: 0.75rem;
  background: linear-gradient(to right, var(--brand-peach, #ff6b6b), var(--brand-rose, #ff8787));
  color: var(--dark-text, #1a1a1a);
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.2s;
}

.login-modal .submit-button:hover {
  opacity: 0.9;
}

.login-modal .submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
} 