.admin-panel-modal {
  position: absolute;
  top: 4rem;
  bottom: 5rem;
  left: 0;
  right: 0;
  z-index: 30;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: var(--background-primary, #121212);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
}

.admin-panel {
  width: 100%;
  max-width: 900px;
  margin: 1.5rem auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  background: rgba(38, 38, 38, 0.3);
  border-radius: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.panel-header {
  position: sticky;
  top: 0;
  background: rgba(26, 26, 26, 0.98);
  z-index: 10;
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 1.5rem 1.5rem 0 0;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.panel-header h2 {
  font-size: clamp(1.5rem, 5vw, 2rem);
  font-family: 'Modulus Pro', sans-serif;
  font-weight: 700;
  background: linear-gradient(135deg, #ffb48f 0%, #ff7e79 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}

.admin-content {
  flex: 1;
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  width: 100%;
}

.info-section, 
.users-section {
  background: rgba(255, 255, 255, 0.03);
  padding: 1.5rem;
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.3s ease;
}

.info-section:hover,
.users-section:hover {
  background: rgba(255, 255, 255, 0.05);
  transform: translateY(-2px);
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  color: #f3f3f3;
  margin-bottom: 0.5rem;
  font-size: 0.95rem;
}

.form-input {
  width: 100%;
  padding: 0.75rem;
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.75rem;
  color: #f3f3f3;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.form-input:focus {
  border-color: #ffb48f;
  box-shadow: 0 0 0 2px rgba(255, 180, 143, 0.1);
  outline: none;
}

.user-lists {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  margin: 1.5rem 0;
}

.admins-list, 
.members-list {
  background: rgba(0, 0, 0, 0.2);
  padding: 1.25rem;
  border-radius: 0.75rem;
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.panel-footer {
  position: sticky;
  bottom: 0;
  background: rgba(26, 26, 26, 0.98);
  padding: 1.25rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  border-radius: 0 0 1.5rem 1.5rem;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
}

.close-button {
  background: rgba(255, 255, 255, 0.1);
  border: none;
  color: #f3f3f3;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.close-button:hover {
  background: rgba(255, 255, 255, 0.15);
  transform: scale(1.1);
}

.info-section h3, 
.users-section h3 {
  font-size: 1.25rem;
  font-weight: 600;
  background: linear-gradient(135deg, #ffb48f 0%, #ff7e79 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 1rem;
}

.remove-user {
  background: none;
  border: none;
  color: #ff7e79;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.2s ease;
  opacity: 0.7;
}

.remove-user:hover {
  opacity: 1;
  background: rgba(255, 126, 121, 0.1);
}

.add-user-form {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
}

.add-user-btn {
  padding: 0.75rem 1.25rem;
  background: linear-gradient(135deg, #ffb48f 0%, #ff7e79 100%);
  border: none;
  border-radius: 0.75rem;
  color: #1a1a1a;
  font-weight: 600;
  font-size: 0.95rem;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.add-user-btn:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 180, 143, 0.2);
}

.add-user-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.error-message {
  color: #ff6b6b;
  margin: 0.5rem 0;
  font-size: 0.9rem;
}

.save-button {
  padding: 0.75rem 1.25rem;
  font-size: 0.95rem;
  font-weight: 600;
  border: none;
  border-radius: 0.75rem;
  cursor: pointer;
  transition: all 0.3s ease;
  background: linear-gradient(135deg, #ffb48f 0%, #ff7e79 100%);
  color: #1a1a1a;
}

.save-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 180, 143, 0.2);
}

.save-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: #444;
  color: #888;
}

.add-user-container {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.user-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: rgba(38, 38, 38, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 0.75rem;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  margin-top: 0.5rem;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.suggestion-item {
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #f3f3f3;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.suggestion-item:hover {
  background: rgba(255, 180, 143, 0.1);
}

.suggestion-item:last-child {
  border-bottom: none;
}

@media (min-width: 768px) {
  .admin-content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .admin-panel {
    margin: 1rem auto;
  }

  .panel-header,
  .admin-content,
  .panel-footer {
    padding: 1rem;
  }

  .info-section,
  .users-section {
    padding: 1rem;
  }
}

@supports (padding-top: env(safe-area-inset-top)) {
  .admin-panel-modal {
    top: calc(4rem + env(safe-area-inset-top));
    bottom: calc(5rem + env(safe-area-inset-bottom));
    padding-bottom: calc(1rem + env(safe-area-inset-bottom));
  }
}

.profile-photo-section,
.cover-photo-section {
  margin-bottom: 2rem;
  text-align: center;
}

.profile-photo-container {
  width: 200px;
  height: 200px;
  margin: 0 auto 1rem;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  background: rgba(0, 0, 0, 0.2);
  border: 2px dashed rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
}

.cover-photo-container {
  width: 100%;
  height: 240px;
  margin: 0 auto 1rem;
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  background: rgba(0, 0, 0, 0.2);
  border: 2px dashed rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
}

.profile-photo-container:hover,
.cover-photo-container:hover {
  border-color: rgba(255, 180, 143, 0.5);
}

.profile-photo-container img,
.cover-photo-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-photo-placeholder,
.cover-photo-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, rgba(255, 180, 143, 0.05) 0%, rgba(255, 126, 121, 0.05) 100%);
}

.profile-photo-placeholder svg,
.cover-photo-placeholder svg {
  width: 48px;
  height: 48px;
  opacity: 0.3;
  color: var(--text-primary);
}

.profile-photo-overlay,
.cover-photo-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.profile-photo-container:hover .profile-photo-overlay,
.cover-photo-container:hover .cover-photo-overlay {
  opacity: 1;
}

.upload-button {
  background: linear-gradient(135deg, #ffb48f 0%, #ff7e79 100%);
  border: none;
  padding: 0.75rem 1.25rem;
  border-radius: 0.75rem;
  color: #1a1a1a;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.upload-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 180, 143, 0.2);
}

.photo-input {
  display: none;
} 