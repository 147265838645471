/* settings.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8); /* Dark semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensures it sits above other elements */
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  
  .modal-overlay.visible {
    visibility: visible;
    opacity: 1;
  }
  
  .modal-content {
    width: 90%;
    max-width: 900px;
    max-height: 90vh;
    background: #1a1a1a;
    border-radius: 12px;
    padding: 2rem;
    overflow-y: auto;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #f0f0f0;
  }
  
  .base-button {
    padding: 0.5rem 1rem;
    background: #2d2d2d;
    border: 1px solid #3d3d3d;
    border-radius: 6px;
    color: white;
    cursor: pointer;
    transition: all 0.2s ease;
    font-size: 0.9rem;
    font-weight: 500;
  }
  
  .base-button:hover {
    background: #3d3d3d;
    transform: translateY(-1px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }
  
  .base-button:active {
    transform: translateY(0);
  }
  
  .base-button:disabled {
    background-color: #777;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
  
  .tool-item {
    transition: transform 0.2s ease;
    padding: 1rem;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.05);
    margin-bottom: 0.75rem;
    border: 1px solid transparent;
  }
  
  .tool-item:hover {
    transform: translateX(4px);
    background: rgba(255, 255, 255, 0.08);
    border-color: rgba(255, 255, 255, 0.1);
  }
  
  .settings-title {
    font-family: 'Modulus Pro', sans-serif;
    font-weight: 700;
    font-size: 2.2rem;
    margin: 1rem 0;
    color: #f0f0f0;
  }
  
  /* Settings Navigation */
  .settings-nav {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
  }
  
  .settings-nav-item {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .settings-nav-item:hover {
    background: rgba(255, 255, 255, 0.08);
  }
  
  .settings-nav-item.active {
    background: rgba(255, 255, 255, 0.12);
    font-weight: 500;
  }
  
  .settings-nav-item svg {
    margin-right: 0.75rem;
    font-size: 1.1rem;
  }
  
  /* Settings Content */
  .settings-content {
    padding: 1rem;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 8px;
    margin-bottom: 1.5rem;
  }
  
  .settings-section {
    margin-bottom: 2rem;
  }
  
  .settings-section-title {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #f0f0f0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 0.5rem;
  }
  
  /* Form Controls */
  .form-group {
    margin-bottom: 1.25rem;
  }
  
  .form-label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    color: #ccc;
  }
  
  .form-control {
    width: 100%;
    padding: 0.75rem;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    color: #f0f0f0;
    transition: all 0.2s ease;
  }
  
  .form-control:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.3);
    background: rgba(255, 255, 255, 0.08);
  }
  
  /* Relationship Smorgasbord Styles */
  .smorgasbord-grid {
    display: grid;
    gap: 1.5rem;
  }
  
  .smorgasbord-category {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 1rem;
    padding: 1.5rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .smorgasbord-item {
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    padding: 1rem;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
  }
  
  .smorgasbord-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .smorgasbord-item.selected {
    animation: selectPulse 1s ease-out;
  }
  
  /* New styles for circular wheel layout */
  .category-wheel {
    position: relative;
    width: 450px;
    height: 450px;
    margin: 0 auto;
  }
  
  .wheel-segment {
    position: absolute;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .wheel-segment:hover {
    filter: brightness(1.2);
  }
  
  .wheel-segment.active {
    filter: brightness(1.3);
  }
  
  .wheel-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: rgba(30, 30, 40, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    z-index: 10;
    text-align: center;
    transition: all 0.3s ease;
  }
  
  .item-card {
    background: rgba(40, 40, 50, 0.7);
    border-radius: 10px;
    padding: 1rem;
    transition: all 0.2s ease;
    cursor: pointer;
    border: 1px solid rgba(255, 255, 255, 0.1);
    overflow: hidden;
  }
  
  .item-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    background: rgba(50, 50, 60, 0.7);
    border-color: rgba(236, 72, 153, 0.3);
  }
  
  .intensity-bar {
    height: 4px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    overflow: hidden;
  }
  
  .intensity-fill {
    height: 100%;
    background: linear-gradient(to right, #ec4899, #8b5cf6);
    transition: width 0.3s ease;
  }
  
  .status-badge {
    padding: 0.2rem 0.6rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 500;
    transition: all 0.2s ease;
  }
  
  .status-badge.yes {
    background-color: #10b981;
    color: white;
  }
  
  .status-badge.no {
    background-color: #ef4444;
    color: white;
  }
  
  .status-badge.maybe {
    background-color: #f59e0b;
    color: black;
  }
  
  .status-badge.future {
    background-color: #3b82f6;
    color: white;
  }
  
  /* Details modal styling */
  .details-modal {
    background: rgba(30, 30, 40, 0.95);
    border-radius: 16px;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(12px);
    max-width: 600px;
    width: 100%;
    padding: 1.5rem;
    margin: 2rem auto;
  }
  
  .details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;
  }
  
  .status-buttons {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1.25rem;
  }
  
  .status-button {
    padding: 0.35rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.85rem;
    transition: all 0.2s ease;
    border: none;
    cursor: pointer;
  }
  
  .notes-area {
    width: 100%;
    min-height: 100px;
    padding: 0.75rem;
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    color: #f0f0f0;
    margin-bottom: 1.25rem;
    font-family: inherit;
    resize: vertical;
  }
  
  .partner-section {
    background: rgba(20, 20, 25, 0.6);
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 1.25rem;
  }
  
  /* Animations */
  @keyframes selectPulse {
    0% {
      box-shadow: 0 0 0 0 rgba(236, 72, 153, 0.4);
    }
    70% {
      box-shadow: 0 0 0 10px rgba(236, 72, 153, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(236, 72, 153, 0);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(0.9);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  /* Add animation classes */
  .fade-in {
    animation: fadeIn 0.3s ease forwards;
  }
  
  .scale-in {
    animation: scaleIn 0.3s ease forwards;
  }
  
  /* Responsive adjustments */
  @media (max-width: 960px) {
    .category-wheel {
      width: 350px;
      height: 350px;
    }
    
    .wheel-center {
      width: 120px;
      height: 120px;
    }
  }
  
  @media (max-width: 768px) {
    .category-wheel {
      width: 300px;
      height: 300px;
    }
    
    .wheel-center {
      width: 100px;
      height: 100px;
    }
  }
  
  @media (max-width: 640px) {
    .smorgasbord-layout {
      flex-direction: column;
    }
    
    .category-wheel {
      width: 280px;
      height: 280px;
      margin-bottom: 2rem;
    }
    
    .wheel-center {
      width: 90px;
      height: 90px;
      font-size: 0.9rem;
    }
  }
  
  /* Modal Responsive Styles */
  @media (max-width: 768px) {
    .modal-content {
      width: 95%;
      max-height: 90vh;
      margin: 5vh auto;
      padding: 1.5rem;
    }
    
    .smorgasbord-grid {
      grid-template-columns: 1fr;
    }
  }
  
  @media (min-width: 769px) {
    .modal-content {
      width: 90%;
      max-width: 1200px;
      max-height: 85vh;
      margin: 7.5vh auto;
    }
    
    .smorgasbord-grid {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }
  
  /* Accessibility Styles */
  .smorgasbord-item:focus-visible {
    outline: 2px solid #ec4899;
    outline-offset: 2px;
  }
  
  /* Animation for item selection */
  .smorgasbord-item-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  
  .smorgasbord-item-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }
  
  .smorgasbord-item-exit {
    opacity: 1;
    transform: scale(1);
  }
  
  .smorgasbord-item-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
  
  /* Add custom button styling */
  .btn-primary {
    padding: 0.5rem 1rem;
    background-color: rgba(236, 72, 153, 0.2); /* pink-500/20 */
    color: rgb(249, 168, 212); /* pink-300 */
    border: 1px solid rgba(236, 72, 153, 0.3); /* pink-500/30 */
    border-radius: 0.5rem;
    font-weight: 500;
    transition: all 0.2s ease;
    cursor: pointer;
  }
  
  .btn-primary:hover {
    background-color: rgba(236, 72, 153, 0.3); /* pink-500/30 */
  }
  
  .btn-primary:active {
    transform: translateY(1px);
  }
  
  .btn-primary:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  